h3, h1 {
    font-family: 'Cabin';
    color: white;
}

.add-to-pkg-button {
    background-color: #3a7ae2;
    border: none;
    color: white;
    padding: 2rem 4rem;
    border-radius: 10px;
    font-size: 1.5rem;
    font-weight: bold;
    font-family: 'Rubik';
    /* margin: 0% 10%; */
    transition: 500ms all;
}

.add-to-pkg-button-wrapper :hover {
    background-color: #2B2D30;
    cursor: pointer;
}

.pkg-wrapper {
    background-color: #2B2D30;
    width: 20rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    border-radius: 10px;
    padding: 2% 1%;
    margin-bottom: 5%;
}

.pkg-wrapper>* {
    margin-bottom: 10px;
}

.pkg-thumbnail {
    max-width: 90%;
    height: auto;
    border-radius: 15px;
}

.pkg-name {
    text-align: center;
    color: white;
    font-family: 'Cabin';
    font-size: 2rem;
    font-weight: bold;
}

.pkg-summary {
    text-align: center;
    color: white;
    font-family: 'Cabin';
    font-size: 1rem;
}

.pkg-button {
    font-size: large;
    font-weight: bold;
    background-color: #c6394d;
    padding: 10px;
    color: white;
    border: none;
    text-decoration: none;
    transition: 300ms all;
    border-radius: 10px;
    width: 100%;
}

.pkg-button-wrapper {
    background-color: #c6394d;
    border-radius: 10px;
    margin: 10px;
}

.pkg-button-wrapper :hover {
    color: white;
    background-color: #3a7ae2;
    cursor: pointer;
}