


.home-container {
    display: grid;
    grid-template-columns: 3fr 3fr;
    grid-auto-flow: row;
    /* background-color: #5a0fa0; */
    padding-top: 8%;
    padding-bottom: 10%;
    /* background-color: blueviolet; */
    margin-left: 10%;
    margin-right: 10%; 
    /* border-radius: 10px; */
}

.left {
    /* display: block; */
    float: left;
    width: 30%;
    padding-left: 8%;
    height: 10rem;
    /* background-color: red; */

}

.right {
    /* display: block; */
    float: right;
    width: 30%;
    padding: 8%;
    /* height: 10rem; */
    background-color: #5a0fa0;
    border-radius: 10px;
    box-shadow: 0px 12px 18px -6px rgba(0, 0, 0, 0.6);
}

.name {
    font-family: 'Poppins';
    font-size: 6rem;
    font-weight: bold;
    text-align: left;
    /* margin-left: 20%; */
    /* color: #d3d3d3; */
    color: floralwhite;
    /* animation-name: fade;
    animation-duration: 1s;
    animation-timing-function: ease-in; */

}

.description {
    font-family: 'Rubik';
    font-size: 2rem;
    font-weight: 500;
    color: rgb(199, 196, 190);
    background: #0f0d11ea;
    border-radius: 10px;
    text-align: left;
    padding: 3%;
    box-shadow: 0px 12px 18px -6px rgba(0, 0, 0, 0.6);


}

p {
    /* animation-name: fade;
    animation-duration: 1s;
    animation-timing-function: ease-in; */
}

h3 {
    /* color: rgb(199, 196, 190); */
    /* animation-name: fade;
    animation-duration: 1s;
    animation-timing-function: ease-in; */
}

.welcome {
    color: rgb(199, 196, 190);

}

/* .fade-in {
    animation: fade 0.9 ease-in;
} */

@keyframes fade {
    from {opacity: 0%;}
    to {opacity: 100%;}
}

@keyframes slide-right {
    from {padding-left: 0%;}
    from {padding-right: 100%;}
}


@media only screen and (max-width: 1400px) {

    .home-container {
        grid-template-columns: 1fr;
    }

    .name {
        font-size: 3rem;
        padding-bottom: 10%;
    }
    .description {
        font-size: 2rem;
        align-self: center;
        margin-right: 0%;
    }
  }

  .parallax {
    background-image: url("hao-wang-pVq6YhmDPtk-unsplash.jpg");
    
    height: 100%;
  
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }