

.tweak-name {
    text-align: center;
    font-size: 3rem;
    font-family: 'Cabin';
}

.tweak-description {
    color: white;
    text-align: left;
    font-size: 1rem;
    font-family: 'Rubik';
    padding-left: 10%;
    padding-right: 10%;
}

#carousel {
    width: 100%;
    height: fit-content;
    background-color: #0f0d11;
    
    overflow: auto;
    white-space:nowrap;

    padding-bottom: 2%;
}

#carousel .slide {
    display: inline-block;
    padding-right: 5%;
}

.tweak-img {
    max-width: 400px;
    height: auto;
}