.navbar-container {
    background-color: #c6394d;
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    position: sticky;
    top: 0;
    width: 100%;
    /* background-image: url("2065692.jpg"); */
}

.navbar-item {
    transition: 300ms all;
    display: flex;
    color: white;
    text-align: center;
    text-decoration: none;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    height: 2rem;
    font-size: 1.5rem;
    font-family: 'Cabin';
    font-weight: bold;
    align-items: center;
}

.navbar-item-wrapper :hover {
    background-color: #2e2e2e;
}

.navbar-icons {
    display: block;
    height: 2rem;
    /* padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1rem;
    padding-right: 1rem; */
} 

.active {
    background-color: #0f0d11;
}

body {
    margin: 0;
    background-color: #0f0d11;
}

@media only screen and (max-width: 1400px) {

    .navbar-item {
        font-size: 1rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
    
  }