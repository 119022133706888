@import url("aos/dist/aos.css");
/* body {
    background: rgb(0,221,255);
    background: -moz-linear-gradient(90deg, rgba(0,221,255,0.648879620207458) 0%, rgba(77,72,199,1) 100%);
    background: -webkit-linear-gradient(90deg, rgba(0,221,255,0.648879620207458) 0%, rgba(77,72,199,1) 100%);
    background: linear-gradient(90deg, rgba(0,221,255,0.648879620207458) 0%, rgba(77,72,199,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00ddff",endColorstr="#4d48c7",GradientType=1);
} */

.projects-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: row;
    justify-items: center;
    margin-left: 10%;
    margin-right: 10%;
    padding-top: 5%;
    height: 100%;
    overflow: visible;
    /* row-gap: 5%; */
    column-gap: 0;
    /* background-color: #6947a0; */
    border-radius: 10px;
    /* background: rgb(68,78,170);
    background: -moz-linear-gradient(180deg, rgba(68,78,170,1) 0%, rgba(116,153,213,1) 100%);
    background: -webkit-linear-gradient(180deg, rgba(68,78,170,1) 0%, rgba(116,153,213,1) 100%);
    background: linear-gradient(180deg, rgba(68,78,170,1) 0%, rgba(116,153,213,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#444eaa",endColorstr="#7499d5",GradientType=1);  */
}

.projects-background {
    /* Add a blur to this image */
    background-image: url(pawel-czerwinski-bX9B9c-YasY-unsplash.jpg);
    height: 100%;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.projects-header {
    display: grid;
    justify-items: center;
}

.project-container {
    display: flex;
    flex-direction: column;
    background-color: #2B2D30;
    border-radius: 10px;
    margin-left: 10%;
    margin-right: 10%;
    padding: 5%;
    margin-bottom: 5%;
    text-align: left;
    width: 60%;
    overflow: hidden;
    box-shadow: 0px 12px 18px -6px rgba(0, 0, 0, 0.6);
}

.project-description {
    font-family: 'Cabin';
    color: white;
}

.project-title {
    font-family: 'Cabin';
    font-size: 2rem;
    color: white;
}

.project-language {
    font-family: 'Cabin';
    color: white;
}

.projects-title {
    font-family: 'Rubik';
    color: white;
    text-align: center;
}

.hidden {
    display: none;
}

.link-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin: 10px 0 10px 0;
    padding: 10px;
    background-color: rgb(29, 29, 29);
    border-radius: 10px;
    width: fit-content;
}

.link-wrapper :hover {
    /* background-color: #2B2D30; */
    color: white;
    cursor: pointer;
}

.github-icon {
    max-height: 1.5rem;
}

@media only screen and (max-width: 1200px) {
    .projects-container {
        grid-template-columns: 1fr;
        margin-left: 5%;
        margin-right: 5%;
    }
}

.project-thumbnail {
    height: auto;
    max-width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
}